<template>
  <div class="ficha-titulo">
        <h1>{{ indicador }}</h1>
        <h3>{{ periodo }}</h3>
    </div>
</template>

<script>
export default {
    props: {
        indicador: {
            type: String
        },
        periodo: {
            type: String
        }

    }
}
</script>

<style lang="scss" scoped>

    .ficha-titulo {
            width:70%;
            height:50px;
            margin:10px auto;
            display: flex; align-items:center; justify-content:space-evenly;
            border-bottom:1px solid black;


            & h1, h3 {
                font-size:22px;
                font-weight: bold;
            }
        }
        
</style>